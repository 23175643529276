import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1><a parentName="h1" {...{
        "href": "https://epikso.com"
      }}>{`Epikso`}</a></h1>
    <h2>{`Summary`}</h2>
    <p>{`During my time at Epikso, a consulting company, I had the unique opportunity
to work on a wide range of projects that were completely unrelated to one another.
In just a matter of months, I transitioned from building a 2D barns builder web application
to working as a data modeler.`}</p>
    <p>{`Working in such a dynamic environment taught me a lot, but one of the most significant takeaways
was the importance of keeping things simple. In my previous roles, where startup culture was prevalent,
I learned to prioritize functionality over efficiency, often creating complex solutions and adding
unnecessary overhead to my algorithms. At Epikso, I learned how to make my code modular, and more efficient,
this by selecting better data structures, DDD, and SOLID principles.`}</p>
    <p>{`On the soft skills side of things, I also learned a great deal about resilience during my time at Epikso.
This was largely due to the pandemic, which brought about many changes to my personal and professional life.
As I navigated this new reality, I focused on keeping my family safe and finding ways to persevere through`}<br parentName="p"></br>{`
`}{`circumstances that were beyond my control.`}</p>
    <h2>{`Challenges`}</h2>
    <p>{`The journey had a lot of challenges and these are some of them. They aren't in order of difficulty`}</p>
    <ul>
      <li parentName="ul">{`SAML IDP (identity provider) and SP (Service Provider)`}</li>
      <li parentName="ul">{`Data Modeling with multiple, not related data sources`}</li>
    </ul>
    <TechTags title="Technologies" tags={["AngularJS", "Python", "AWS EC2", "SSO", "SAML", "NodeJS", "Mysql", "Oracle DB", "SQL Server", "MongoDB", "PostgreSQL", "PM2", "FabricJS", "PHP", "Symfony"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      